import { styled } from '@mui/material/styles'
import { Link } from '@mui/material'
import Stack from 'components/commons/Stack'
import Button from 'components/commons/Button'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'

type StyledPurchasedBadgeProps = { badgeColor: string }

export const StyledCard = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.primary,
  width: '168px',
  height: 'auto',
  position: 'relative',
  padding: '8px',
  cursor: 'pointer',
  borderRadius: '12px',
  gap: '12px',
  boxShadow: `0px 0px 8px 0px ${theme.palette.border.bold}`,
  [theme.breakpoints.up('md')]: {
    marginRight: '16px',
    width: 'auto',
    maxWidth: '211px',
  },
  [theme.breakpoints.up('lg')]: {
    marginRight: '0px',
    width: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    width: '168px',
    height: 'auto',
  },
}))

export const StyledImage = styled(Box)(({ theme }) => ({
  height: '163px',
  img: {
    borderRadius: '12px',
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '100%',
  },
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '152px',
    img: {
      height: '152px',
    },
  },
}))

export const StyledBoxType = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  background: theme.palette.background.tertiery,
  borderRadius: '4px',
  height: '20px',
  padding: '2px 4px',
  color: theme.palette.text.secondary,
  width: 'max-content',
  ...theme.typography.smallRegular,
  [theme.breakpoints.up('md')]: {
    height: '22px',
    ...theme.typography.normalRegular,
  },
}))

export const StyledTitle = styled(Link)(({ theme }) => ({
  ...theme.typography.normalBold,
  color: theme.palette.text.primary,
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minHeight: '38px',
  wordBreak: 'normal',
  [theme.breakpoints.up('md')]: {
    ...theme.typography.mediumBold,
  },
  ':hover': {
    color: theme.palette.tiptip[500],
  },
  zIndex: 5,
  textDecoration: 'none',
}))

export const StyledPrice = styled(Typography)(({ theme }) => ({
  ...theme.typography.normalBold,
  color: theme.palette.text.primary,
  marginRight: '4px',
  [theme.breakpoints.up('md')]: {
    ...theme.typography.h3,
  },
}))

export const StyledSalePrice = styled(Typography)(({ theme }) => ({
  ...theme.typography.smallRegular,
  color: theme.palette.text.secondary,
  marginRight: '4px',
  textDecorationLine: 'line-through',
  [theme.breakpoints.up('md')]: {
    ...theme.typography.normalRegular,
  },
}))

export const StyledPriceContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

export const StyledPurchasedBadge = styled(
  Stack,
)<StyledPurchasedBadgeProps>(({ theme, badgeColor }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '12px',
  fontSize: '12px',
  color: theme.palette.text.primary,
  svg: {
    color: badgeColor,
    height: '16px',
    width: '16px',
    marginRight: '6px',
  },
}))

export const StyledButtonContainer = styled(Button)({
  width: '84px',
  height: '84px',
  padding: 0,
  position: 'absolute',
  marginLeft: 'auto',
  marginRight: 'auto',
  right: 0,
  left: 0,
  top: '25%',
})

export const StyledExclusiveContentContainer = styled(Box)(
  ({ theme }) => ({
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    right: 0,
    left: 0,
    zIndex: 5,
    background: theme.palette.background.primary,
    opacity: 0.8,
    height: '163px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0 8px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 16px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '152px',
    },
  }),
)

export const StyledExclusiveLabel = styled(Typography)(
  ({ theme }) => ({
    ...theme.typography.smallRegular,
    [theme.breakpoints.up('md')]: {
      ...theme.typography.normalRegular,
    },
    color: theme.palette.text.primary,
  }),
)

export const StyledLockIcon = styled('img')({
  height: '100%',
})

export const StyledInfoSection = styled(Stack)(({ theme }) => ({
  gap: '8px',
  [theme.breakpoints.down('sm')]: {
    gap: '4px',
  },
}))
